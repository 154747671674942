function menuScripts() {
    // верхнее меню без фона над баннером, если баннер с самого верха
    var banner = document.querySelector('.banner-fromtop')
    if (!banner) {
        document.querySelector("header").classList.add('has_bg')
    }
    // появление-исчезновение верхнего меню при прокрутке
    var topPos = 0
    function hideHeader() {
        var header = document.querySelector("header"),
            bannerHeight = 0
            if (banner) {
                bannerHeight = banner.clientHeight
            }
        if (window.pageYOffset > 70) {
            if (window.pageYOffset >= topPos) {
                header.classList.add('movedOut')
            } else {
                if (window.pageYOffset > bannerHeight) {
                    header.classList.add('has_bg')
                    header.classList.remove('movedOut')
                } else {
                    header.classList.remove('has_bg')
                }
            }
            topPos = window.pageYOffset
        } else {
            header.classList.remove('movedOut')
        }
    }
    window.addEventListener("scroll", hideHeader)

    // MOBILE MENU
    var burger 	= document.querySelector('.sidebar-toggle'),
        sidebar = document.querySelector('.sidebar'),
        body 	= document.querySelector('body')
    function showSidebar() {
        sidebar.classList.add('is-active')
        body.classList.add('sidebar-opened')
    }
    function hideSidebar() {
        sidebar.classList.remove('is-active')
        body.classList.remove('sidebar-opened')
    }
    burger.addEventListener('click', showSidebar)
    document.querySelector('.sidebar-close').addEventListener('click', hideSidebar)
    document.querySelector('.sidebar-overlay').addEventListener('click', hideSidebar)
}
document.addEventListener("DOMContentLoaded", menuScripts);
